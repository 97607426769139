import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button, Container, Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import pageOne from "../../assets/Sam_Azimi_Resume_DS_Page1.png";
import pageTwo from "../../assets/Sam_Azimi_Resume_DS_Page2.png";

import React, { useState, useContext, useEffect  } from "react";
import { useNavigate } from "react-router-dom";

import CoreCompetencies from "../../components/Section/CoreCompetencies";
// import theme from "../../theme";
import { useTheme } from "@mui/material/styles";
import ToggleEduWork from "../../ui/ToggleEduWork";
import { DataContext } from "../../context/DataContext";


import { useDialog } from '../../context/DialogContext';

// import { CORE_COMPETENCIES as dataSet } from "../../data/DS_RES_DATA";

export function MyResume() {
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const theme = useTheme();
  const isMobile = theme.breakpoints.down("sm");
  const { dataSet } = useContext(DataContext);
  const pdfLink = dataSet.PUBLIC_RESUME_PDF_LINK[0];

  const handleIconClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  // under construction dialogue
  const { showDialog } = useDialog();

  useEffect(() => {
      showDialog();
  }, []);


  return (
    <>
      <Container sx={{ marginY: `2rem` }}>
        {/* pdf navigate buttons */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 4 }}
          sx={{ justifyContent: `center`, marginBottom: `2rem` }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "secondary.yellow",
              color: "secondary.dark",
              borderRadius: "2rem",
              width: "relative",
              "&:hover": {
                color: "white",
                bgcolor: "primary.main",
              },
            }}
            startIcon={<CloudDownloadIcon />}
            onClick={() => handleIconClick(pdfLink)}
          >
            Download pdf
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              bgcolor: "primary.main",
              borderRadius: "2rem",
              width: "relative",
              "&:hover": {
                bgcolor: "secondary.yellow",
                color: "secondary.dark",
              },
            }}
            startIcon={<PictureAsPdfIcon />}
            onClick={() => handleIconClick(pdfLink)}
          >
            View pdf
          </Button>
        </Stack>
        {/* IMG of resume pdf 2x pages */}
        {/* <Stack alignItems={`center`} spacing={4}>
        <Paper elevation={2}>
          <LazyLoadImage
            alt="page 1 of sam's resume"
            px={2}
            width={"100%"}
            src={pageOne}
          />
        </Paper>
        <Paper>
          <LazyLoadImage
            alt="page 2 of sam's resume"
            px={2}
            width={"100%"}
            src={pageTwo}
          />
        </Paper>
        </Stack> */}

        {/* TODO: Add dynamic resume here: */}

        <Stack alignItems={`center`} spacing={4} padding={4}>
          <Paper elevation={2}>
            {/* My section 3 - Core Competencies*/}
            <Box
              width={"100vw"}
              boxShadow={4}
              sx={{
                bgcolor: "secondary.main",
                color: "white",
                p: 4,
              }}
            >
              <CoreCompetencies />
            </Box>

            {/* My section 4 - experience/education*/}
            <Box
              width={"100vw"}
              boxShadow={4}
              sx={{
                bgcolor: "white",
                color: "black",
                py: 4,
                px: { xs: 1, sm: 2, lg: 4, xl: 10 },
              }}
            >
              {/* this is my experience/education section */}
              <ToggleEduWork />
            </Box>

            {/* My section 5 - Skills*/}
            <Box
              width={"100vw"}
              boxShadow={4}
              sx={{
                bgcolor: "secondary.main",
                py: 4,
              }}
            >
              {/* this is My Skills section */}
            </Box>
          </Paper>
        </Stack>
      </Container>
    </>
  );
}
