import {
  Box,
  Grid,
  IconButton,
  Paper,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

import CardFeeder from "../CardFeeder";

export default function TestemonialSection() {
  const [expanded, setExpanded] = useState(false);

  // Function to toggle the expanded state
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid container>
        {/* This is the title of the section / maybe a slogan */}
        <Grid
          item
          xs={12}
          // paddingLeft={`3rem`}
          sx={{
            paddingLeft: { xs: "1rem", sm: "3rem" },
            paddingBottom: `2rem`,
          }}
        >
          <Typography variant="h4">Clients always come first</Typography>
        </Grid>
        {/* This is  the Testemonials */}
        <Grid item xs={12} sm={5} order={{ xs: 2, sm: 1 }}>
          <Box
            sx={{
              height: "20rem",
              borderRadius: "1rem",
              margin: { xs: ".75rem", sm: "2rem" },
              px: 2,
              paddingTop: "1rem",
            }}
          >
            <CardFeeder />
          </Box>
        </Grid>

        {/* This is the paragraph */}
        <Grid item xs={12} sm={7} order={{ xs: 1, sm: 2 }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "1rem",
              margin: { xs: ".75rem", sm: "2rem" },
              px: 2,
              paddingTop: "1rem",
            }}
          >
            <Typography
              variant="body"
              paragraph
              // paddingTop={4}

              fontSize={{ xs: "1em", sm: "1.25em" }}
              // fontSize={`1.25em`}
              // align={{sm:"justify"}}
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",

                // TODO: add a isTable (8 to 9)
                WebkitLineClamp: expanded ? "none" : 6, // 'none' when expanded, 6 lines otherwise
                lineClamp: expanded ? "none" : 6, // fallback for non-webkit browsers
              }}
            >
              As a dedicated data analyst, my commitment to exceptional customer
              satisfaction and service is unwavering. I prioritize understanding
              each client's unique needs and objectives, ensuring that my data
              insights are not on accurate but also highly relevant and
              actionable. By maintaining open lines of communication and
              fostering strong, collaborative relationships, I am able to
              deliver personalized support and guidance throughout our
              partnership. My goal is to provide clear, concise data analyses
              that empower your business to make informed decisions and achieve
              success. Trust me to deliver not just expert data analysis, but
              also an outstanding customer experience that exceeds your
              expectations.
            </Typography>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <IconButton aria-label="Show More" onClick={handleToggle}>
                {expanded ? (
                  <>
                    <Typography variant="subtitle2">see less</Typography>
                    <ExpandLessIcon />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">see more</Typography>
                    <ExpandMoreIcon />
                  </>
                )}
              </IconButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
