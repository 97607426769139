import React, { useState } from 'react';
import { getTestCardByNumber, getTestCardById, createTestCard, updateTestCard, deleteTestCard } from './services/ackrooService';

const CardDetails = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [ackrooId, setAckrooId] = useState('');
  const [cardDetails, setCardDetails] = useState(null);
  const [error, setError] = useState(null);

  const handleFetchByNumber = async () => {
    try {
      const details = await getTestCardByNumber(cardNumber);
      setCardDetails(details);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFetchById = async () => {
    try {
      const details = await getTestCardById(ackrooId);
      setCardDetails(details);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCreateCard = async () => {
    try {
      const newCardData = { /* Your card data object here */ };
      const response = await createTestCard(newCardData);
      setCardDetails(response);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdateCard = async () => {
    try {
      const updatedCardData = { /* Your updated card data object here */ };
      const response = await updateTestCard(ackrooId, updatedCardData);
      setCardDetails(response);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteCard = async () => {
    try {
      const response = await deleteTestCard(ackrooId);
      setCardDetails(null);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h1>Fetch Card Details</h1>
      
      <div>
        <h2>By Card Number</h2>
        <input
          type="text"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          placeholder="Enter Card Number"
        />
        <button onClick={handleFetchByNumber}>Fetch by Card Number</button>
      </div>
      
      <div>
        <h2>By Ackroo ID</h2>
        <input
          type="text"
          value={ackrooId}
          onChange={(e) => setAckrooId(e.target.value)}
          placeholder="Enter Ackroo ID"
        />
        <button onClick={handleFetchById}>Fetch by Ackroo ID</button>
      </div>
      
      <div>
        <h2>Create Card</h2>
        <button onClick={handleCreateCard}>Create Card</button>
      </div>

      <div>
        <h2>Update Card</h2>
        <button onClick={handleUpdateCard}>Update Card</button>
      </div>

      <div>
        <h2>Delete Card</h2>
        <button onClick={handleDeleteCard}>Delete Card</button>
      </div>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {cardDetails && (
        <div>
          <h2>Card Details</h2>
          <pre>{JSON.stringify(cardDetails, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default CardDetails;
