import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="right" padding={".5rem"} mr={2}>
      {"Copyright © "}
      <Link color="inherit" href="https://samazimi.com/">
        Sam Azimi
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
