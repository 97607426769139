// IconConveyor.js
import React, { useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

// This component handles the seamless scrolling effect
function IconConveyor({ iconData }) {
  const containerRef = useRef(null);
  const itemsRef = useRef(null);

   // Define breakpoints
   const isLarge = useMediaQuery('(min-width:1440px)'); // Large screens (1440px and up)
   const isMedium = useMediaQuery('(min-width:1024px)'); // Medium screens (1024px and up)
   const isTablet = useMediaQuery('(min-width:768px)'); // Tablet screens (768px and up)
   const isMobile = useMediaQuery('(min-width:320px)'); // Tablet screens (768px and up)
   const isTiny = useMediaQuery('(max-width:320px)'); // Tablet screens (768px and up)
 
  useEffect(() => {
    const container = containerRef.current;
    const items = itemsRef.current;

    if (container && items) {
      const itemWidth = items.children[0].offsetWidth + 16; // Add the spacing (16px)
      const itemCount = items.children.length / 2; // Adjust for duplicated items
    //   const widthFactor = 2.7; // screen sizes between tablet:768 = 5.5,  medium:1024 = 3.5, large:1440 = 2.7 

      // Determine the width factor based on screen size using switch
      let widthFactor = 2.7; // default for large screens

      switch (true) {
        case isLarge:
          widthFactor = 2.7;
          break;
        // case isMedium:
        //   widthFactor = 3.5;
        //   break;
        case isTablet:
          widthFactor = 5.5;
          break;
        case isMobile:
          widthFactor = 5.5;
          break;
        case isTiny:
          widthFactor = 18;
          break;
        default:
          widthFactor = 5.5; // Fallback value
      }

      const totalWidth = itemWidth * itemCount/widthFactor; 

      items.style.width = `${totalWidth}px`; // Adjust the width for duplicated items

      let start = Date.now();
      const duration = 20000; // Duration of the animation

      function scroll() {
        const now = Date.now();
        const elapsed = now - start;

        if (elapsed >= duration) {
          start = now;
        }
        const progress = (elapsed % duration) / duration;
        const offset = -totalWidth * progress;
        items.style.transform = `translateX(${offset}px)`;

        requestAnimationFrame(scroll);
      }

      scroll();
    }
  }, [iconData]);

  return (
    <Container ref={containerRef} sx={{ overflow: 'hidden', whiteSpace: 'nowrap'}}>
      <Stack direction="row" spacing={1} ref={itemsRef} 
      sx={{ display: 'inline-flex' }}
      >
        {iconData.concat(iconData).map((item, i) => (
          <React.Fragment key={i}>
            {i > 0 && i % iconData.length !== 0 && <Divider orientation="vertical" flexItem />}
            <Typography>{item}</Typography>
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
}

export default IconConveyor;
