// this is for my ThemeContext
import React, { createContext, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const ThemeContext = createContext();

// this is the baseTheme applied everywhere in the app
const baseTheme = {
  typography: {
    displayHeading: {
      fontSize: "1.3rem",
    },
    displayBody: {
      fontSize: "1.1rem",
    },

    sloganH4: {
      fontSize: "1.75rem",
      fontWeight: 700,
      transition: "font-size 0.3s ease-in-out",

      "@media (min-width:425px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
      "@media (min-width:768px)": {
        fontSize: "2.3rem",
      },
      "@media (min-width:900px)": {
        fontSize: "2rem",
      },
      "@media (min-width:992px)": {
        fontSize: "2.3rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:1440px)": {
        fontSize: "3rem",
      },
      "@media (min-width:1536px)": {
        fontSize: "3.5rem",
      },
      "@media (min-width:2560px)": {
        fontSize: "5rem",
      },
    },
    sloganH5: {
      fontSize: "1.5rem",
      transition: "font-size 0.3s ease-in-out",
      "@media (min-width:425px)": {
        fontSize: "1.75rem",
      },
      "@media (min-width: )": {
        fontSize: "2.3rem",
      },
      "@media (min-width:900px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width:992px)": {
        fontSize: "1.75rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "2rem",
      },
      "@media (min-width:1440px)": {
        fontSize: "2.7rem",
      },
      "@media (min-width:1536px)": {
        fontSize: "3rem",
      },
      "@media (min-width:2560px)": {
        fontSize: "3.5rem",
      },
    },
  },
};

// this is the custom theme based on context

const themes = {
  TPM: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: "#1371DC",
        dark: "#1C39BB",
        light: "#9EC4EE",
      },
      secondary: {
        yellow: "#FFFD91", //KEEP
        main: "#1CCBFF",
        dark: "#073297",
        light: "#41F7F4",
      },
      tertiary: {
        main: "#00DAF1", //where is this used?
        dark: "#017783", //where is this used?
        light: "#ADF1FA", //where is this used?
      },
    },
    gradient: {
      start: "#1C39BB",
      mid: "#6E91DB",
      end: "#9EC4EE",
    },
  }),
  DS: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: "#A157FF",
        dark: "#7848AB",
        light: "#CCA3FF",
      },
      secondary: {
        yellow: "#FFFD91",
        main: "#FE8C2B",
        dark: "#7848AB",
        light: "#CCA3FF",
      },
      tertiary: {
        main: "#00DAF1",
        dark: "#017783",
        light: "#ADF1FA",
      },
    },
    gradient: {
      start: "#7848AB",
      mid: "#B084E3",
      end: "#CCA3FF",
      // start: '#A157FF',
      // end: '#A157FF',
    },
  }),
  FS: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: "#DA6518",
        dark: "#664F7B",
        light: "#FFF58D",
      },
      secondary: {
        yellow: "#FFFD91", //KEEP
        main: "#B52862",
        dark: "#DA6518",
        light: "#B38D24",
      },
      tertiary: {
        main: "#00DAF1", //where is this used?
        dark: "#017783", //where is this used?
        light: "#ADF1FA", //where is this used?
      },
    },
    gradient: {
      start: "#664F7B",
      mid: "#D2C488",
      end: "#FFF58D",
      // start: '#DA6518',
      // end: '#664F7B',
      // TODO:
      // make start and end to get their color from primary.dark and primary.main
    },
  }),
};

const ThemeContextProvider = ({ children }) => {
  const [themeName, setThemeName] = useState("TPM");

  const changeTheme = (newTheme) => {
    setThemeName(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ themeName, changeTheme }}>
      <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
