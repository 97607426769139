// THIS IS THE FULL STACK DEVELOPMENT DATA
// AKA STACK DEVELOPMENT
const FS_RES_DATA = {
  SUBJECT_FOCUS: ["Stack Development"],
  PROFESSION:["Front End Developer"],
  PUBLIC_RESUME_PDF_LINK: [
    "",
    // "https://drive.google.com/file/d/1zBkiwMTAfDU53_MxDmProTsGqnRvuZ1b/view?usp=sharing",
  ],
  EDUCATION_CERTIFICATION: [
    {
      orderNumber: 1,
      title: "Bachelor of Engineering & Management",
      subject: "Electrical and Computer Engineering",
      institute: "McMaster University",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Combined Engineering and MBA programs",
        "Business Analytics | Economics | Accounting | Finance | Marketing",
        "Mathematics | Data Modeling | Statistical Analysis",
        "VBA | Excel | MATLAB | R | C++",
      ],
      description:
        "Completed (with honors) double-major equivalent in Engineering and MBA",
      details: [
        "Gained knowledge and expertise in Mathematics, Data Modeling, Statistical Analysis and Project Management",
        "Learned and implemented programming languages including: VBA, Excel, MATLAB, R, C++",
        "Advanced Mathematics, Strategic Thinking, Problem Solving & Analytical Skills",
      ],
      url: "",
      totYear: "",
      startYear: "2002",
      endYear: "2008",
      myFave: [
        "MATLAB",
        "Mathematics",
        "Statistics",
        "Data Analytics",
        "Financial Modeling",
        "Business Intelligence",
        "Reporting",
      ],
    },
    {
      orderNumber: 2,
      title: "Data Science",
      subject: "AI Machine Learning & Python",
      institute: "Udemy",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Data Collection & Cleaning | Normalization | Standardization | Categorical Encoding | Feature Scaling",
        "Correlation & Regression Modeling | Data Visualization | Classification | Clustering",
        "Mathematics | Data Modeling | Statistical Analysis",
        "Colab Notebooks | NumPy | Pandas",
      ],
      description:
        "Developed new skills and improved understanding of field of Data Science",
      details: [
        "Mastering Machine Learning with Python, created robust Machine Learning models & accurate predictions",
        "Prepared ML analysis to handle Reinforcement Learning, NLP and Deep Learning.",
        "Implemented combination of advanced techniques like Dimensionality Reduction to solve any ML problem",
      ],
      url: "",
      totYear: "",
      startYear: "2023",
      endYear: "2024",
      myFave: [
        "pyplot",
        "Jupyter Notebook",
        "Data Analytics",
        "scikit-learn",
        "Data Visualization",
        "Matplotlib",
        "seaborn",
        "Reporting",
        "Tableu",
      ],
    },
    {
      orderNumber: 3,
      title: "Software Engineering Bootcamp",
      subject: "MERN Stack",
      institute: "University of Toronto",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "SQL",
        "No-SQL",
        "Database Query",
        "JavaScript",
        "Node.js",
      ],
      description:
        "Developed applications using JavaScript & React libraries with both SQL and NoSQL databases",
      details: [
        "Conceptualized, developed, and deployed web applications with JavaScript, React, Node.js, and MongoDB",
        "Created responsive user interfaces and single page applications (SPA) using React and Redux",
        "Implemented backend APIs with Express.js and managed SQL and NoSQL databases for data management",
        "Designed and implemented unit tests and integration tests using Jest",
      ],
      url: "",
      totYear: "",
      startYear: "2022",
      endYear: "2023",
      myFave: [
        "MySQL",
        "React",
        "JavaScript",
        "Vite",
        "Mongo DB",
        "NoSQL",
        "SQL Queries",
        "Server API",
      ],
    },
    {
      orderNumber: 4,
      title: "Database Design",
      subject: "SQL Structure & Queries",
      institute: "University of Waterloo",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Python",
        "R & RStudio",
        "MATLAB",
        "JavaScript (ES5, ES6+)",
      ],
      description:
        "Learned and implemented the structured query language to collect and manipulate data from databases",
      details: [
        "To be added 1",
        "To be added 2",
        "To be added 3",
        "To be added 4",
      ],
      url: "",
      totYear: "",
      startYear: "",
      endYear: "",
      myFave: [
        "Joins",
        "Relational Keys",
        "Select Queries",
        "Data Modelling",
        "Troubleshooting",
        "Schema",
      ],
    },
    {
      orderNumber: 5,
      title: "Computer Science",
      subject: "Data Structure & Algorithms",
      institute: "Udemy",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Python",
        "R & RStudio",
        "MATLAB",
        "JavaScript (ES5, ES6+)",
      ],
      description: "This is where I learned all the good stuff",
      details: [
        "Improved problem-solving skills becoming a stronger Engineer implementing patterns in Dynamic Programming",
        "Mastering Recursion, searching & sorting, linked lists, trees, heaps, hash tables, graphs, & Dijkstra's Algorithm",
      ],
      url: "",
      totYear: "",
      startYear: "2023",
      endYear: "2024",
      myFave: ["Python", "Big O", "JavaScript", "TypeScript"],
    },

    /** this is for other profiles */
    // {
    //   orderNumber: 6,
    //   title: "Advanced React",
    //   subject:
    //     "React Best Practices with Router, Redux, Next.js, and Material UI",
    //   institute: "Udemy",
    //   instituteLogo: "will put svg here",
    //   descriptionBullets:[
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //   ],
    //   description: "This is where I learned all the good stuff",
    //   details: [
    //     "Built Full-Stack React apps with Next.js and implemented user authentication & Unit Tests in React apps.",
    //     "Managed complex states with React's Context API & React Redux, and Routing with React Router best practices.",
    //   ],
    //   url: "",
    //   totYear: "",
    //   startYear: "",
    //   endYear: "",
    //   myFave: [
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //     "TypeScript",
    //   ],
    // },
    // {
    //   orderNumber: 7,
    //   title: "UI/UX Design",
    //   subject: "Figma Best Practices for UI/UX Design",
    //   institute: "Udemy",
    //   instituteLogo: "will put svg here",
    //   descriptionBullets:[
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //   ],
    //   description: "This is where I learned all the good stuff",
    //   details: [
    //     "Mastered UI Design, User Interface, User Experience design, UX Design & Web Design.",
    //     "Designed UI Components using Constraints & Multi-Dimensional Variants.",
    //     "Built UX projects from beginning to end, and created own icons, buttons & other UI components.",
    //     "Created fully interactive prototypes with advanced micro interactions, page transitions & animations.",
    //     "Utilized Figma Developer Mode to extract and manupulate design parameter codes for implementation and deployment.",
    //   ],
    //   url: "",
    //   totYear: "",
    //   startYear: "",
    //   endYear: "",
    //   myFave: [
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //     "TypeScript",
    //   ],
    // },
    // {
    //   orderNumber: 8,
    //   title: "Advanced CSS",
    //   subject: "CSS Functions and Post Processing with SASS",
    //   institute: "Udemy",
    //   instituteLogo: "will put svg here",
    //   descriptionBullets:[
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //   ],
    //   description: "This is where I learned all the good stuff",
    //   details: [
    //     "Gained expertise with CSS Variables, Functions, Advance Selectors, Pseudo-Classes, Grid, Flexbox, & Animation.",
    //     "Mastered CSS Preprocessing with SASS Variables, Mixins, Functions, Nesting, Inheritance, & Transformation.",
    //   ],
    //   url: "",
    //   totYear: "",
    //   startYear: "",
    //   endYear: "",
    //   myFave: [
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //     "TypeScript",
    //   ],
    // },
  ],
  PROFESSIONAL_EXPERIENCE: [
    // {
    //   orderNumber: 1,
    //   title: "Front End Developer",
    //   subject: "Generative Artificial Inteligence",
    //   institute: "Extropolis AI",
    //   instituteLogo: "will put svg here",
    //   descriptionBullets:[
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //   ],
    //   description:
    //     "Coordinated seamless collaboration between technical teams, stakeholders, and clients, ensuring effective execution of project objectives within a Generative AI industry. Passionately driven, leveraged React and JavaScript expertise to craft intuitive and visually engaging user interfaces, enhancing the accessibility and usability of AI-powered tools.",
    //   details: [
    //     "Crafted dynamic front-end solutions using React and JavaScript, fostering immersive user experiences for Generative AI applications.",
    //     "Collaborated closely with design and engineering teams to translate complex AI algorithms into intuitive and visually compelling user interfaces.",
    //     "Implemented responsive design principles and optimized performance to ensure seamless functionality across various devices and platforms, enhancing accessibility for diverse user demographics.",
    //   ],
    //   url: "https://diffusitron.ai/",
    //   totYear: "",
    //   startYear: "",
    //   endYear: "",
    //   myFave: [
    //     "Python",
    //     "R & RStudio",
    //     "MATLAB",
    //     "JavaScript (ES5, ES6+)",
    //     "TypeScript",
    //   ],
    // },
    {
      orderNumber: 1,
      title: "Data Analyst",
      subject: "Integrative Medical Clinic",
      institute: "Toronto Metabolic Clinic",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Excel | VLOOKUP | Pivot | VBA | Macros",
        "Data Modelling | Statistical Analysis | 3rd Party DBMS providers",
        "Data Collection | Cleaning | Normalization | Standardization ",
        "Financial Reports | Statistical Reports | Projections | Forecasting",
        "Data Visualization | Classification | Clustering | Reporting",
      ],
      description:
        "Collected, cleaned, and processed various data related to the business operations and regularly presented reports to shareholders",
      details: [
        "Prepared Progress Reports, Financial Reports, Projections, Forecasts",
        "Presented data to stakeholders through reports and visualizations",
        "Applied industry best practices to data collection and analysis processes for efficiency and accuracy",
        // "Researched emerging technologies and data analysis methodologies to contribute to continuous improvement initiatives.",
      ],
      url: " https://www.torontometabolicclinic.com/",
      totYear: "5",
      startYear: "2019",
      endYear: "2024",
      myFave: [
        "VLOOKUP",
        "Financial Reports",
        "Macros",
        "Data Modelling",
        "Data Visualization",
        "Reporting",
        "Financial Model",
      ],
    },
    {
      orderNumber: 2,
      title: "Data Analytics",
      subject: "Various companies within Ontario Solar Industry",
      institute: "Ontario Solar Industry",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Data Collection | Cleaning | Normalization | Standardization ",
        "Financial Reports | Statistical Reports | Projections | Forecasting",
        "Excel | VLOOKUP | Pivot | VBA | Macros",
        "Data Modeling | Statistical Analysis | 3rd Party DBMS providers",
        "Data Visualization | Classification | Clustering | Reporting",
      ],
      description:
        "Played a pivotal role in harmonizing data exchanges between technical and business domains, facilitating clear communication and translating technical data into actionable insights for non-technical stakeholders. Orchestrated the detailed lifecycle of data and energy projects, including data collection, analysis, presentation, and strategic implementation",
      details: [
        "Spearheaded the development of proprietary software enabling the collection and analysis of energy data from various sources, optimizing residential rooftop assessments using aerial imagery obtained from Google Maps.",
        "Managed a comprehensive array of data-centric tasks including energy data collection, regulatory compliance, technical evaluations, procurement, energy data analysis, construction strategizing, subcontractor coordination, installation oversight, and commissioning protocols.",
        "Facilitated informed decision-making processes and contributed to the optimization of operational workflows.",
        "Obtained Professional Engineering certification and license from the Professional Engineers of Ontario (P.Eng.).",
      ],
      url: "",
      totYear: "6",
      startYear: "2012",
      endYear: "2018",
      myFave: [
        "VLOOKUP",
        "Financial Reports",
        "Macros",
        "Data Modelling",
        "Data Visualization",
        "Reporting",
        "Financial Model",
      ],
    },
    {
      orderNumber: 3,
      title: "Data Reporting",
      subject: "Mining Industry",
      institute: "SNC Lavalin",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Financial Reports | Statistical Reports | Projections | Forecasting",
        "Progress Reporting | Monitoring KPI's | Data Visualization | Reporting",
        "Excel | VLOOKUP | Pivot| VBA | Macros | MS Office",
      ],
      description:
        "Reported weekly on project progress and KPI status on our engineering projects in remote locations in Saskatchewan",
      details: [
        "Spearheaded the development of proprietary software enabling the collection and analysis of energy data from various sources, optimizing residential rooftop assessments using aerial imagery obtained from Google Maps.",
        "Managed a comprehensive array of data-centric tasks including energy data collection, regulatory compliance, technical evaluations, procurement, energy data analysis, construction strategizing, subcontractor coordination, installation oversight, and commissioning protocols.",
        "Facilitated informed decision-making processes and contributed to the optimization of operational workflows.",
        "Obtained Professional Engineering certification and license from the Professional Engineers of Ontario (P.Eng.).",
      ],
      url: " https://www.torontometabolicclinic.com/",
      totYear: "2",
      startYear: "2010",
      endYear: "2012",
      myFave: [
        "KPI's",
        "Weekly Reports",
        "MS Word",
        "Data Collections",
        "Excel",
        "Data Visualization",
        "Reporting",
        "Progress Models",
      ],
    },
  ],
  CORE_COMPETENCIES: [
    "Accessibility",
    "API Integration",
    "User Interface Design",
    "JavaScript Frameworks",
    "Performance Optimization",
    "Testing & Debugging",
    "Version Control & Collaboration",
    "Build Tools & Automation",
    "Server-Side Programming",
    "Database Management",
    "API Development",
    "DevOps & Deployment",
    "Security Best Practices",
    "Scalability & Performance",
    "Testing & Quality Assurance",
    "Strategic Planning & Team Leadership"
  ],
  TECHNICAL_PROFICIENCIES: [
    // DataScience
    {
      Languages: [
        "Python",
        "R & RStudio",
        "MATLAB",
        "JavaScript (ES5, ES6+)",
        "TypeScript",
        "SQL",
        "NoSQL",
        "JSON",
        "MERN Stack",
      ],
      Tools: [
        "Git",
        "Webpack",
        "Babel",
        "NPM",
        "Yarn",
        "Redux",
        "Docker",
        "JSON Web Token",
        "MySQL",
        "CloudFlare",
      ],
      Methodologies: [
        "Agile",
        "Scrum",
        "Test Driven Development",
        "Object Oriented Programming",
        "JIRA",
        "Trello",
        "Confluence",
      ],
      FrontEnd: [
        "React",
        "Redux",
        "Vite",
        "Material UI",
        "Mantine",
        "SASS/SCSS",
        "MVC",
        "SVG",
        "HTML",
        "CSS",
      ],
      BackEnd: [
        "Node.js",
        "Express.js",
        "Next.js",
        "RESTful APIs",
        "MongoDB",
        "SQL",
        "NoSQL",
        "GraphQL",
        "Apollo",
      ],
      Deployment: ["Heroku", "Firebase", "GCloud", "GitHub Pages"],
      Testing: ["Jest", "Cypress", "Mocha"],
    },
  ],
};

export default FS_RES_DATA;

// export const PUBLIC_RESUME_PDF_LINK = [
//   "https://drive.google.com/file/d/1zBkiwMTAfDU53_MxDmProTsGqnRvuZ1b/view?usp=sharing"
// ];
//  Define your education and certification data
// export const EDUCATION_CERTIFICATION = [
//   {
//     orderNumber: 1,
//     title: "Bachelor of Engineering & Management",
//     subject: "Electrical and Computer Engineering",
//     institute: "McMaster University",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Combined Engineering and MBA programs",
//       "Business Analytics | Economics | Accounting | Finance | Marketing",
//       "Mathematics | Data Modeling | Statistical Analysis",
//       "VBA | Excel | MATLAB | R | C++",
//     ],
//     description:
//       "Completed (with honors) double-major equivalent in Engineering and MBA",
//     details: [
//       "Gained knowledge and expertise in Mathematics, Data Modeling, Statistical Analysis and Project Management",
//       "Learned and implemented programming languages including: VBA, Excel, MATLAB, R, C++",
//       "Advanced Mathematics, Strategic Thinking, Problem Solving & Analytical Skills",
//     ],
//     url: "",
//     totYear: "",
//     startYear: "2002",
//     endYear: "2008",
//     myFave: [
//       "MATLAB",
//       "Mathematics",
//       "Statistics",
//       "Data Analytics",
//       "Financial Modeling",
//       "Business Intelligence",
//       "Reporting",
//     ],
//   },
//   {
//     orderNumber: 2,
//     title: "Data Science",
//     subject: "AI Machine Learning & Python",
//     institute: "Udemy",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Data Collection & Cleaning | Normalization | Standardization | Categorical Encoding | Feature Scaling",
//       "Correlation & Regression Modeling | Data Visualization | Classification | Clustering",
//       "Mathematics | Data Modeling | Statistical Analysis",
//       "Colab Notebooks | NumPy | Pandas",
//     ],
//     description:
//       "Developed new skills and improved understanding of field of Data Science",
//     details: [
//       "Mastering Machine Learning with Python, created robust Machine Learning models & accurate predictions",
//       "Prepared ML analysis to handle Reinforcement Learning, NLP and Deep Learning.",
//       "Implemented combination of advanced techniques like Dimensionality Reduction to solve any ML problem",
//     ],
//     url: "",
//     totYear: "",
//     startYear: "2023",
//     endYear: "2024",
//     myFave: [
//       "pyplot",
//       "Jupyter Notebook",
//       "Data Analytics",
//       "scikit-learn",
//       "Data Visualization",
//       "Matplotlib",
//       "seaborn",
//       "Reporting",
//       "Tableu",
//     ],
//   },
//   {
//     orderNumber: 3,
//     title: "Software Engineering Bootcamp",
//     subject: "MERN Stack",
//     institute: "University of Toronto",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "SQL",
//       "No-SQL",
//       "Database Query",
//       "JavaScript",
//       "Node.js",
//     ],
//     description:
//       "Developed applications using JavaScript & React libraries with both SQL and NoSQL databases",
//     details: [
//       "Conceptualized, developed, and deployed web applications with JavaScript, React, Node.js, and MongoDB",
//       "Created responsive user interfaces and single page applications (SPA) using React and Redux",
//       "Implemented backend APIs with Express.js and managed SQL and NoSQL databases for data management",
//       "Designed and implemented unit tests and integration tests using Jest",
//     ],
//     url: "",
//     totYear: "",
//     startYear: "2022",
//     endYear: "2023",
//     myFave: [
//       "MySQL",
//       "React",
//       "JavaScript",
//       "Vite",
//       "Mongo DB",
//       "NoSQL",
//       "SQL Queries",
//       "Server API",
//     ],
//   },
//   {
//     orderNumber: 4,
//     title: "Database Design",
//     subject: "SQL Structure & Queries",
//     institute: "University of Waterloo",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Python",
//       "R & RStudio",
//       "MATLAB",
//       "JavaScript (ES5, ES6+)",
//     ],
//     description:
//       "Learned and implemented the structured query language to collect and manipulate data from databases",
//     details: [
//       "To be added 1",
//       "To be added 2",
//       "To be added 3",
//       "To be added 4",
//     ],
//     url: "",
//     totYear: "",
//     startYear: "",
//     endYear: "",
//     myFave: [
//       "Joins",
//       "Relational Keys",
//       "Select Queries",
//       "Data Modelling",
//       "Troubleshooting",
//       "Schema",
//     ],
//   },
//   {
//     orderNumber: 5,
//     title: "Computer Science",
//     subject: "Data Structure & Algorithms",
//     institute: "Udemy",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Python",
//       "R & RStudio",
//       "MATLAB",
//       "JavaScript (ES5, ES6+)",
//     ],
//     description: "This is where I learned all the good stuff",
//     details: [
//       "Improved problem-solving skills becoming a stronger Engineer implementing patterns in Dynamic Programming",
//       "Mastering Recursion, searching & sorting, linked lists, trees, heaps, hash tables, graphs, & Dijkstra's Algorithm",
//     ],
//     url: "",
//     totYear: "",
//     startYear: "2023",
//     endYear: "2024",
//     myFave: ["Python", "Big O", "JavaScript", "TypeScript"],
//   },

//   /** this is for other profiles */
//   // {
//   //   orderNumber: 6,
//   //   title: "Advanced React",
//   //   subject:
//   //     "React Best Practices with Router, Redux, Next.js, and Material UI",
//   //   institute: "Udemy",
//   //   instituteLogo: "will put svg here",
//   //   descriptionBullets:[
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //   ],
//   //   description: "This is where I learned all the good stuff",
//   //   details: [
//   //     "Built Full-Stack React apps with Next.js and implemented user authentication & Unit Tests in React apps.",
//   //     "Managed complex states with React's Context API & React Redux, and Routing with React Router best practices.",
//   //   ],
//   //   url: "",
//   //   totYear: "",
//   //   startYear: "",
//   //   endYear: "",
//   //   myFave: [
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //     "TypeScript",
//   //   ],
//   // },
//   // {
//   //   orderNumber: 7,
//   //   title: "UI/UX Design",
//   //   subject: "Figma Best Practices for UI/UX Design",
//   //   institute: "Udemy",
//   //   instituteLogo: "will put svg here",
//   //   descriptionBullets:[
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //   ],
//   //   description: "This is where I learned all the good stuff",
//   //   details: [
//   //     "Mastered UI Design, User Interface, User Experience design, UX Design & Web Design.",
//   //     "Designed UI Components using Constraints & Multi-Dimensional Variants.",
//   //     "Built UX projects from beginning to end, and created own icons, buttons & other UI components.",
//   //     "Created fully interactive prototypes with advanced micro interactions, page transitions & animations.",
//   //     "Utilized Figma Developer Mode to extract and manupulate design parameter codes for implementation and deployment.",
//   //   ],
//   //   url: "",
//   //   totYear: "",
//   //   startYear: "",
//   //   endYear: "",
//   //   myFave: [
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //     "TypeScript",
//   //   ],
//   // },
//   // {
//   //   orderNumber: 8,
//   //   title: "Advanced CSS",
//   //   subject: "CSS Functions and Post Processing with SASS",
//   //   institute: "Udemy",
//   //   instituteLogo: "will put svg here",
//   //   descriptionBullets:[
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //   ],
//   //   description: "This is where I learned all the good stuff",
//   //   details: [
//   //     "Gained expertise with CSS Variables, Functions, Advance Selectors, Pseudo-Classes, Grid, Flexbox, & Animation.",
//   //     "Mastered CSS Preprocessing with SASS Variables, Mixins, Functions, Nesting, Inheritance, & Transformation.",
//   //   ],
//   //   url: "",
//   //   totYear: "",
//   //   startYear: "",
//   //   endYear: "",
//   //   myFave: [
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //     "TypeScript",
//   //   ],
//   // },
// ];

//  Define your professional experience data
// export const PROFESSIONAL_EXPERIENCE = [
//   // {
//   //   orderNumber: 1,
//   //   title: "Front End Developer",
//   //   subject: "Generative Artificial Inteligence",
//   //   institute: "Extropolis AI",
//   //   instituteLogo: "will put svg here",
//   //   descriptionBullets:[
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //   ],
//   //   description:
//   //     "Coordinated seamless collaboration between technical teams, stakeholders, and clients, ensuring effective execution of project objectives within a Generative AI industry. Passionately driven, leveraged React and JavaScript expertise to craft intuitive and visually engaging user interfaces, enhancing the accessibility and usability of AI-powered tools.",
//   //   details: [
//   //     "Crafted dynamic front-end solutions using React and JavaScript, fostering immersive user experiences for Generative AI applications.",
//   //     "Collaborated closely with design and engineering teams to translate complex AI algorithms into intuitive and visually compelling user interfaces.",
//   //     "Implemented responsive design principles and optimized performance to ensure seamless functionality across various devices and platforms, enhancing accessibility for diverse user demographics.",
//   //   ],
//   //   url: "https://diffusitron.ai/",
//   //   totYear: "",
//   //   startYear: "",
//   //   endYear: "",
//   //   myFave: [
//   //     "Python",
//   //     "R & RStudio",
//   //     "MATLAB",
//   //     "JavaScript (ES5, ES6+)",
//   //     "TypeScript",
//   //   ],
//   // },
//   {
//     orderNumber: 1,
//     title: "Data Analyst",
//     subject: "Integrative Medical Clinic",
//     institute: "Toronto Metabolic Clinic",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Excel | VLOOKUP | Pivot | VBA | Macros",
//       "Data Modelling | Statistical Analysis | 3rd Party DBMS providers",
//       "Data Collection | Cleaning | Normalization | Standardization ",
//       "Financial Reports | Statistical Reports | Projections | Forecasting",
//       "Data Visualization | Classification | Clustering | Reporting",
//     ],
//     description:
//       "Collected, cleaned, and processed various data related to the business operations and regularly presented reports to shareholders",
//     details: [
//       "Prepared Progress Reports, Financial Reports, Projections, Forecasts",
//       "Presented data to stakeholders through reports and visualizations",
//       "Applied industry best practices to data collection and analysis processes for efficiency and accuracy",
//       // "Researched emerging technologies and data analysis methodologies to contribute to continuous improvement initiatives.",
//     ],
//     url: " https://www.torontometabolicclinic.com/",
//     totYear: "5",
//     startYear: "2019",
//     endYear: "2024",
//     myFave: [
//       "VLOOKUP",
//       "Financial Reports",
//       "Macros",
//       "Data Modelling",
//       "Data Visualization",
//       "Reporting",
//       "Financial Model",
//     ],
//   },
//   {
//     orderNumber: 2,
//     title: "Data Analytics",
//     subject: "Various companies within Ontario Solar Industry",
//     institute: "Ontario Solar Industry",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Data Collection | Cleaning | Normalization | Standardization ",
//       "Financial Reports | Statistical Reports | Projections | Forecasting",
//       "Excel | VLOOKUP | Pivot | VBA | Macros",
//       "Data Modeling | Statistical Analysis | 3rd Party DBMS providers",
//       "Data Visualization | Classification | Clustering | Reporting",
//     ],
//     description:
//       "Played a pivotal role in harmonizing data exchanges between technical and business domains, facilitating clear communication and translating technical data into actionable insights for non-technical stakeholders. Orchestrated the detailed lifecycle of data and energy projects, including data collection, analysis, presentation, and strategic implementation",
//     details: [
//       "Spearheaded the development of proprietary software enabling the collection and analysis of energy data from various sources, optimizing residential rooftop assessments using aerial imagery obtained from Google Maps.",
//       "Managed a comprehensive array of data-centric tasks including energy data collection, regulatory compliance, technical evaluations, procurement, energy data analysis, construction strategizing, subcontractor coordination, installation oversight, and commissioning protocols.",
//       "Facilitated informed decision-making processes and contributed to the optimization of operational workflows.",
//       "Obtained Professional Engineering certification and license from the Professional Engineers of Ontario (P.Eng.).",
//     ],
//     url: "",
//     totYear: "6",
//     startYear: "2012",
//     endYear: "2018",
//     myFave: [
//       "VLOOKUP",
//       "Financial Reports",
//       "Macros",
//       "Data Modelling",
//       "Data Visualization",
//       "Reporting",
//       "Financial Model",
//     ],
//   },
//   {
//     orderNumber: 3,
//     title: "Data Reporting",
//     subject: "Mining Industry",
//     institute: "SNC Lavalin",
//     instituteLogo: "will put svg here",
//     descriptionBullets: [
//       "Financial Reports | Statistical Reports | Projections | Forecasting",
//       "Progress Reporting | Monitoring KPI's | Data Visualization | Reporting",
//       "Excel | VLOOKUP | Pivot| VBA | Macros | MS Office",
//     ],
//     description:
//       "Reported weekly on project progress and KPI status on our engineering projects in remote locations in Saskatchewan",
//     details: [
//       "Spearheaded the development of proprietary software enabling the collection and analysis of energy data from various sources, optimizing residential rooftop assessments using aerial imagery obtained from Google Maps.",
//       "Managed a comprehensive array of data-centric tasks including energy data collection, regulatory compliance, technical evaluations, procurement, energy data analysis, construction strategizing, subcontractor coordination, installation oversight, and commissioning protocols.",
//       "Facilitated informed decision-making processes and contributed to the optimization of operational workflows.",
//       "Obtained Professional Engineering certification and license from the Professional Engineers of Ontario (P.Eng.).",
//     ],
//     url: " https://www.torontometabolicclinic.com/",
//     totYear: "2",
//     startYear: "2010",
//     endYear: "2012",
//     myFave: [
//       "KPI's",
//       "Weekly Reports",
//       "MS Word",
//       "Data Collections",
//       "Excel",
//       "Data Visualization",
//       "Reporting",
//       "Progress Models",
//     ],
//   },
// ];
// export const CORE_COMPETENCIES = [
//   // DataScience
//   // {
//   //   "Core Competencies": [
//       "Database Management",
//       "Data Architecture",
//       "Data Warehousing",
//       "Machine Learning",
//       "Software Architecture Design",
//       "Version Control",
//       "Technology Trends Analysis",
//       "Agile Methodologies",
//       "Continuous Integration & Deployment",
//       "Team Leadership",
//       "Strategic Planning",
//       "Quality Assurance & Testing",
//       "Cross-Functional Collaborations",
//       "Project Management",
//       "Resource Allocation",
//       "Risk Mitigation",
//       "Software Development Life Cycle (SDLC) Management",
//       // "TODO: Data Story Telling | Data Modelling| Data Visualization | Excel & Power Point | Version Control | Quality Assurance | Strategic Planning | Resource Allocation | Statistical Reports | Projections & Forecasting | Financial Reports | Database Management | Data Architecture | Data Warehousing | Technology Trends Analysis | Agile Methodologies | Team Leadership | Cross-Functional Collaborations | Project Management | Resource Allocation"
//     // ],
//   // },
// ];
// export const TECHNICAL_PROFICIENCIES = [
//   // DataScience
//   {
//     Languages: [
//       "Python",
//       "R & RStudio",
//       "MATLAB",
//       "JavaScript (ES5, ES6+)",
//       "TypeScript",
//       "SQL",
//       "NoSQL",
//       "JSON",
//       "MERN Stack",
//     ],
//     Tools: [
//       "Git",
//       "Webpack",
//       "Babel",
//       "NPM",
//       "Yarn",
//       "Redux",
//       "Docker",
//       "JSON Web Token",
//       "MySQL",
//       "CloudFlare",
//     ],
//     Methodologies: [
//       "Agile",
//       "Scrum",
//       "Test Driven Development",
//       "Object Oriented Programming",
//       "JIRA",
//       "Trello",
//       "Confluence",
//     ],
//     FrontEnd: [
//       "React",
//       "Redux",
//       "Vite",
//       "Material UI",
//       "Mantine",
//       "SASS/SCSS",
//       "MVC",
//       "SVG",
//       "HTML",
//       "CSS",
//     ],
//     BackEnd: [
//       "Node.js",
//       "Express.js",
//       "Next.js",
//       "RESTful APIs",
//       "MongoDB",
//       "SQL",
//       "NoSQL",
//       "GraphQL",
//       "Apollo",
//     ],
//     Deployment: ["Heroku", "Firebase", "GCloud", "GitHub Pages"],
//     Testing: ["Jest", "Cypress", "Mocha"],
//   },
// ];
