import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import DisplayCard from "../DisplayCard/DisplayCard";

export default function EduWorkFeeder({ entry, index }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const iconOrder = isSmallScreen ? 1 : entry.orderNumber % 2 !== 0 ? 1 : 2;
  const contentOrder = isSmallScreen ? 1 : entry.orderNumber % 2 !== 0 ? 2 : 1;

  // console.log(typeof index, index);
  return (
    <>
      <Stack direction="row" spacing={1}>
        <CheckCircleOutlineRoundedIcon
          sx={{
            color: "secondary.main",
            borderRadius: "50%",
            border: "thin",
            order: iconOrder,
          }}
        />
        <Paper
          sx={{
            padding: "1rem",
            minHeight: "5rem",
            color: "white",
            bgcolor: "secondary.main",
            order: contentOrder,
            flexGrow: 1,
            "&:hover": {
              elevation: 3,
              boxShadow:
                "0px 4px 10px rgba(255, 255, 255, 0.3), 0px 6px 15px rgba(255, 255, 255, 0.2)", // Custom white shadow
            },
          }}
          elevation={0}
        >
          {/* THIS WILL CALL THE COMPONENT DisplayCard  */}
          <DisplayCard key={index} entry={entry} />
        </Paper>
      </Stack>
      {/* </Grid> */}
    </>
  );
}
