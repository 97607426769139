import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Stack from "@mui/material/Stack";
import { Button, Fab } from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import IconConveyor from "../IconConveyor/IconConveyor";

export default function DisplayCard({ entry }) {
  const currentYear = new Date().getFullYear();
  const endYear = entry.endYear || currentYear;
  const numberOfYears = entry.startYear ? endYear - entry.startYear : "N/A";

  const boldWords = (text, wordsToBold) => {
    const regex = new RegExp(`(${wordsToBold.join("|")})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      wordsToBold.includes(part) ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <>
      <Stack>
        <Stack direction={`row`} spacing={2} alignItems={`center`}>
          <Box ClassName={`numberOfYears`}>{numberOfYears} Yrs</Box>
          <Typography variant="displayHeading">{entry.title}</Typography>
        </Stack>
        <Stack
          direction={`row`}
          spacing={1}
          justifyContent={`flex-end`}
          alignItems={`center`}
        >
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: "right",
            }}
          >
            {entry.institute}
          </Typography>
          <AccountCircle /> {/* Add your icon here */}
        </Stack>
          <List maxWidth={"40%"}>
            {entry.descriptionBullets.map((bullets, index) => (
              <ListItem  key={index}>
                <ListItemIcon>
                  <ArrowRightIcon fontSize="small" sx={{color:"white"}}/>
                </ListItemIcon>
                <ListItemText sx={{primary: "draft"}} >
                  {/* <Typography variant="displayBody"> */}
                  {boldWords(bullets, ["Engineering", "MBA"])}
                  {/* </Typography> */}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        <Accordion
          sx={{
            bgcolor: "secondary.main",
            color: "white",
            boxShadow: "none",
            "&:hover": {
              border: "solid",
              boxShadow: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
          >
            <Typography>My Achievements</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle1">{entry.subject}</Typography>

              <Typography>{entry.description}</Typography>
              <List>
                {entry.details.map((detail, index) => (
                  <ListItem key={index}>
                    <Typography>{detail}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Stack direction={`row`} spacing={1}>
          <IconConveyor iconData={entry.myFave} />
        </Stack>
      </Stack>
    </>
  );
}
