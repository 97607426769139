// its only function is to map through the dataset and call the render component for each items in the DATASET
import React, { useState, useEffect } from "react";
import { TESTEMONIAL_DATA as DATASET } from "../data/TESTEMONIAL_DATA";
import TCard from "./TCard";
import { motion, AnimatePresence } from "framer-motion";
// import { Fab } from '@mui/material';
// import { ArrowBack, ArrowForward } from '@mui/icons-material';

const fadeZoomIn = {
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, x: -100 },
};

export default function CardFeeder() {
  const [index, setIndex] = useState(0);
  const [showCard, setShowCard] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCard(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % DATASET.length);
        setShowCard(true);
      }, 500); // Duration should match the exit animation duration
    }, 30500); // Interval duration should account for exit + delay + enter

    return () => clearInterval(interval);
  }, []);

  //   const handlePrevious = () => {
  //     setShowCard(false);
  //     setTimeout(() => {
  //       setIndex((prevIndex) => (prevIndex - 1 + DATASET.length) % DATASET.length);
  //       setShowCard(true);
  //     }, 500);
  //   };

    const handleNext = () => {
      setShowCard(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % DATASET.length);
        setShowCard(true);
      }, 500);
    };

  return (
    <>
      <AnimatePresence>
        {showCard && (
          <motion.div
            key={index}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeZoomIn}
            transition={{ duration: 0.5 }}
            whileHover={{
              scale: 1.1,
              // rotate: [0, 10, -10, 10, -10, 0], // wiggle effect
              transition: { duration: 0.5 },
            }}
            onClick={handleNext}
            style={{ cursor: 'pointer' }} // Add cursor pointer to indicate clickability
          >
            <TCard {...DATASET[index]} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
