import React, { createContext, useState, useContext, useEffect } from 'react';

const DialogContext = createContext();

export const useDialog = () => {
    return useContext(DialogContext);
};

export const DialogProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    const showDialog = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 15000);
    };

    return (
        <DialogContext.Provider value={{ open, showDialog }}>
            {children}
        </DialogContext.Provider>
    );
};
