// THIS IS THE TECHNICAL PROJECT MANAGEMENT DATA
// AKA OPERATIONS
const TPM_RES_DATA = {
  SUBJECT_FOCUS: ["Operations Management"],
  PROFESSION:["Operations Manager"],
  PUBLIC_RESUME_PDF_LINK: [
    "https://drive.google.com/file/d/1wOrhbsRr7ZQjGgkp-UHsyjtoqQs6_aSc/view?usp=sharing",
  ],
  EDUCATION_CERTIFICATION: [
    {
      orderNumber: 1,
      title: "Bachelor of Engineering & Management",
      subject: "Electrical and Computer Engineering",
      institute: "McMaster University",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Bachelors' degree in Business & Engineering",
        "Business Analytics | Economics | Accounting | Finance | Marketing",
        "Mathematics | Data Modeling | Statistical Analysis",
        "VBA | Excel | MATLAB | R | C++",
      ],
      description:
        "Completed (with honors) double-major equivalent in Engineering and MBA",
      details: [
        "Gained knowledge and expertise in Mathematics, Data Modeling, Statistical Analysis and Project Management",
        "Learned and implemented programming languages including: VBA, Excel, MATLAB, R, C++",
        "Advanced Mathematics, Strategic Thinking, Problem Solving & Analytical Skills",
      ],
      url: "",
      totYear: "",
      startYear: "2002",
      endYear: "2008",
      myFave: [
        "MATLAB",
        "Mathematics",
        "Statistics",
        "Data Analytics",
        "Financial Modeling",
        "Business Intelligence",
        "Reporting",
      ],
    },
    {
      orderNumber: 2,
      title: "SaaS & SCM",
      subject: "SaaS & Sustaibability in Supply-Chain Management",
      institute: "Technical University of Munich",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "SCM",
        "SaaS",
        "Logistics",
        "Sustainability",
        "Analytics",
      ],
      description:
        "Focused on the role of software in sustainability and efficiency of supply-chain management",
      details: [
        "Gained expertise with SCM processes.",
        "Implemented algorithms and analytics to SCM data",
      ],
      url: "",
      totYear: "1",
      startYear: "2007",
      endYear: "2008",
      myFave: ["VBA", "Excel", "Logistics", "Planning", "MATLAB", "SCM"],
    },
  ],
  PROFESSIONAL_EXPERIENCE: [
    {
      orderNumber: 1,
      title: "Operations Management",
      subject: "Various companies within Ontario Solar Industry",
      institute: "Ontario Solar Industry",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Team Leadership",
        "Daily Operations Management",
        "Customer Expansion Strategies",
        "Process Improvement",
        "Tool Optimization",
        "Stakeholder Relationship Management",
        "Vendor Selection",
        "Recruitment and Training Direction",
      ],
      description:
        "Oversaw daily operations, implemented customer expansion strategies, improved processes and tools, led teams, maintained stakeholder relationships, managed vendor selection, and directed recruitment and training.",
      details: [
        "•	Managed daily operations, ensuring efficiency and effectiveness",
        "•	Developed and implemented strategies for onboarding, supporting, training, retaining, and expanding the customer base, consistently exceeding expectations.",
        "•	Enhanced processes and tools, resulting in improved performance and daily reporting.",
        "•	Obtained Professional Engineering certification and license from the Professional Engineers of Ontario (P.Eng.).",
        "•	Oversaw deployment processes, ensuring timely and accurate service delivery.",
        "•	Led both technical and operational teams, driving team development and performance.",
        "•	Built and maintained strong relationships with clients, vendors, and business leaders.",
        "•	Managed vendor selection, negotiation, and performance",
        "•	Directed recruitment and training of new team members.",
      ],
      url: "",
      totYear: "7",
      startYear: "2012",
      endYear: "2019",
      myFave: [
        "Jira",
        "Confluence",
        "Business Inteligence",
        "Data Modelling",
        "Data Visualization",
        "Reporting",
        "Financial Model",
      ],
    },
    {
      orderNumber: 2,
      title: "	Coordination & Reporting ",
      subject: "SaaS Enterprise Software",
      institute: "BlackBerry",
      instituteLogo: "will put svg here",
      descriptionBullets: [
        "Client Onboarding Management",
        "Client Support Oversight",
        "Technical Support Leadership",
        "Analytics and Reporting",
        "Stakeholder Relationship Building",
        "Client Retention Strategies",
      ],
      description:
        "Managed client onboarding and support, led technical support, conducted analytics and reporting, fostered stakeholder relationships, contributed to improved client retention.",
      details: [
        "•	Managed client onboarding and support for new and existing clients.",
        "•	Led first-line technical support, improving team performance and customer satisfaction.",
        "•	Conducted daily analytics, reporting, and delivery of deployment processes.",
        "•	Fostered strong relationships with internal and external stakeholders, working closely with the CEO, CFO, and Directors of Sales and Marketing.",
        "•	Contributed to improved client retention and cross-selling, making a tangible impact on the business.",
        "•	Oversaw daily operations, focusing on excellence and client satisfaction.",
      ],
      url: " https://www.blackberry.com",
      totYear: "2",
      startYear: "2007",
      endYear: "2009",
      myFave: [
        "KPI's",
        "Weekly Reports",
        "MS Word",
        "Data Collections",
        "Excel",
        "Data Visualization",
        "Reporting",
        "Progress Models",
      ],
    },
  ],
  CORE_COMPETENCIES: [
    "Customer Focus",
    "Operational Efficiency",
    "Analytical",
    "Technical Leadership",
    "Project Management",
    "Operational Leadership",
    "Technology Trends Analysis",
    "Software Architecture Design",
    "Version Control",
    "Agile Methodologies",
    "Strategic Planning",
    "Software Development Life Cycle (SDLC) Management",
  ],
  TECHNICAL_PROFICIENCIES: [
    // DataScience
    {
      Languages: [
        "Python",
        "R & RStudio",
        "MATLAB",
        "JavaScript (ES5, ES6+)",
        "TypeScript",
        "SQL",
        "NoSQL",
        "JSON",
        "MERN Stack",
      ],
      Tools: [
        "Git",
        "Webpack",
        "Babel",
        "NPM",
        "Yarn",
        "Redux",
        "Docker",
        "JSON Web Token",
        "MySQL",
        "CloudFlare",
      ],
      Methodologies: [
        "Agile",
        "Scrum",
        "Test Driven Development",
        "Object Oriented Programming",
        "JIRA",
        "Trello",
        "Confluence",
      ],
      FrontEnd: [
        "React",
        "Redux",
        "Vite",
        "Material UI",
        "Mantine",
        "SASS/SCSS",
        "MVC",
        "SVG",
        "HTML",
        "CSS",
      ],
      BackEnd: [
        "Node.js",
        "Express.js",
        "Next.js",
        "RESTful APIs",
        "MongoDB",
        "SQL",
        "NoSQL",
        "GraphQL",
        "Apollo",
      ],
      Deployment: ["Heroku", "Firebase", "GCloud", "GitHub Pages"],
      Testing: ["Jest", "Cypress", "Mocha"],
    },
  ],
};

export default TPM_RES_DATA;
