import React from "react";
import { Copyright } from "../../components/Copyright/Copyright";
import Box from "@mui/material/Box";

export function AppFooter() {
  return (
    <Box position={"fixed"} bottom={0} width={"100vw"}>
      <Copyright />
    </Box>
  );
}
