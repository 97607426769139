import Grid from "@mui/material/Grid";
import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
// import { PROFESSIONAL_EXPERIENCE } from "../../data/DS_RES_DATA";
import EduWorkFeeder from "./EduWorkFeeder";
import { Typography } from "@mui/material";

export default function ProfessionFeeder() {
  const { dataSet } = useContext(DataContext);

      // console.log(dataSet);
      if (!Array.isArray(dataSet.PROFESSIONAL_EXPERIENCE)) {
        return <Typography>Error: PROFESSIONAL_EXPERIENCE is not an array</Typography>;
      }

  return (
    <>
      {dataSet.PROFESSIONAL_EXPERIENCE.map((entry, index) => (
        <>
          <Grid marginY={5} key={index} item container>
            {index % 2 === 0 ? (
              <>
                <Grid key="item1" item sm={6}></Grid>
                <Grid key="item2" item xs={12} sm={6}>
                  <EduWorkFeeder entry={entry} key={index} />
                </Grid>
              </>
            ) : (
              <>
                <Grid key="item2" item xs={12} sm={6}>
                  <EduWorkFeeder entry={entry} key={index} />
                </Grid>
                <Grid key="item1" item sm={6}></Grid>
              </>
            )}
          </Grid>
        </>
      ))}
    </>
  );
}
