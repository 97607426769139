import Grid from "@mui/material/Grid";
import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
// import { EDUCATION_CERTIFICATION } from "../../data/DS_RES_DATA";
import EduWorkFeeder from "./EduWorkFeeder";
import { Typography } from "@mui/material";
// console.log(dataSet);

export default function EducationFeeder() {
  const { dataSet } = useContext(DataContext);
  
    // console.log(dataSet);
    if (!Array.isArray(dataSet.EDUCATION_CERTIFICATION)) {
      return <Typography>Error: EDUCATION_CERTIFICATION is not an array</Typography>;
    }

  return (
    <>
      {dataSet.EDUCATION_CERTIFICATION.map((entry, index) => (
        <>
          <Grid marginY={5} key={index} item container>
            {index % 2 === 0 ? (
              <>
                <Grid key="item1" item sm={6}></Grid>
                <Grid key="item2" item xs={12} sm={6}>
                  <EduWorkFeeder entry={entry} key={index} />
                </Grid>
              </>
            ) : (
              <>
                <Grid key="item2" item xs={12} sm={6}>
                  <EduWorkFeeder entry={entry} key={index} />
                </Grid>
                <Grid key="item1" item sm={6}></Grid>
              </>
            )}
          </Grid>
        </>
      ))}
    </>
  );
}
