const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SANDBOX_ACCESS_TOKEN = process.env.REACT_APP_SANDBOX_ACCESS_TOKEN;

const fetchOptions = (method, body = null) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${SANDBOX_ACCESS_TOKEN}`,
  };

  const options = { method, headers };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return options;
};

export const getTestCardByNumber = async (cardNumber) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cardnumber_aliases.json?cardnumber_alias=${cardNumber}`, fetchOptions('GET'));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Failed to fetch test card by number:', error);
    throw error;
  }
};

export const getTestCardById = async (ackrooId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cards/${ackrooId}`, fetchOptions('GET'));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Failed to fetch test card by ID:', error);
    throw error;
  }
};

export const createTestCard = async (cardData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/card.json`, fetchOptions('POST', cardData));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Failed to create test card:', error);
    throw error;
  }
};

export const updateTestCard = async (ackrooId, cardData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cards/${ackrooId}`, fetchOptions('PUT', cardData));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Failed to update test card:', error);
    throw error;
  }
};

export const deleteTestCard = async (ackrooId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cards/${ackrooId}.json`, fetchOptions('DELETE'));
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Failed to delete test card:', error);
    throw error;
  }
};


// export const createTestCard = async (cardData) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/cards`, fetchOptions('POST', cardData));
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return response.json();
//   } catch (error) {
//     console.error('Failed to create test card:', error);
//     throw error;
//   }
// };

// export const getTestCard = async (cardId) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/cards/${cardId}`, fetchOptions('GET'));
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return response.json();
//   } catch (error) {
//     console.error('Failed to fetch test card:', error);
//     throw error;
//   }
// };

// export const updateTestCard = async (cardId, cardData) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/cards/${cardId}`, fetchOptions('PUT', cardData));
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return response.json();
//   } catch (error) {
//     console.error('Failed to update test card:', error);
//     throw error;
//   }
// };

// export const deleteTestCard = async (cardId) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/cards/${cardId}`, fetchOptions('DELETE'));
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return response.json();
//   } catch (error) {
//     console.error('Failed to delete test card:', error);
//     throw error;
//   }
// };
