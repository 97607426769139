import { ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AppFooter } from "./AppBar/AppFooter/AppFooter";
import { DrawerAppBar } from "./AppBar/DrawerAppBar";
import { HomePage } from "./pages/HomePage";
import { MyResume } from "./pages/MyResume/MyResume";
// import theme from "./theme";
import ScrollToTopButton from "./components/ScrollToTop";
import MyTracksButtons from "./components/MyTracksButtons";
import MyTracks from "./components/MyTracks";
import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from './context/ThemeContext';
import { DataContext } from './context/DataContext';
// import DS_RES_DATA from "./data/DS_RES_DATA";
// import FS_RES_DATA from "./data/FS_RES_DATA";
// import TPM_RES_DATA from "./data/TPM_RES_DATA";
// import DataComponent from './components/DataComponent';
// import MenuButtons from './components/MenuButtons';
import { Container } from '@mui/material';
import FunWithCodePage from "./funWithCode/FunWithCodePage";


import WeRinBeta from './components/WeRinBeta/WeRinBeta';
import { DialogProvider, useDialog } from './context/DialogContext';

import { useThemeHandler } from "./hooks/useThemeHandler";



export default function App() {
  // const { themeName, changeTheme } = useContext(ThemeContext);
  // const { dataSet, changeDataSet } = useContext(DataContext);
  const { handleThemeChange } = useThemeHandler();

  // const handleThemeChange = (newTheme) => {
  //     changeTheme(newTheme);
  //     switch (newTheme) {
  //       case "TPM":
  //         changeDataSet(TPM_RES_DATA);
  //         break;
  //       case "DS":
  //         changeDataSet(DS_RES_DATA);
  //         break;
  //       case "FS":
  //         changeDataSet(FS_RES_DATA);
  //         break;
  //       default:
  //         break;
  //     };
  // };

  return (
    // <ThemeProvider theme={theme}>
    <Router
    // basename="/samazimidotcom"
    >
      <DialogProvider>
        {/* <WeRinBeta /> */}
        <DrawerAppBar />
        <ScrollToTopButton />
        {/* <MyTracksButtons handleThemeChange={handleThemeChange}/> */}
        <MyTracks handleThemeChange={handleThemeChange} />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/resume" element={<MyResume />} />
          <Route
            exact
            path="/funwithcode/ackroo"
            element={<FunWithCodePage />}
          />
        </Routes>
        <AppFooter />
      </DialogProvider>
    </Router>
    // </ThemeProvider>
  );
}
