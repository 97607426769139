import React, { useEffect, useState, useContext } from "react";
import { Box, Fab, Tooltip } from "@mui/material";
import { ReactComponent as TpmSvg } from "../assets/PMIcon2.svg";
import { ReactComponent as DsSvg } from "../assets/DSicon.svg";
import { ReactComponent as FsSvg } from "../assets/FSicon.svg";
import { motion } from "framer-motion";
import { ThemeContext } from "../context/ThemeContext";

const MyTracksButtons = ({ handleThemeChange }) => {
  const { themeName } = useContext(ThemeContext);
  const [scrolled, setScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
      setIsScrolling(true);
      setOpacity(0.5);
    } else {
      setScrolled(false);
      setIsScrolling(false);
      setOpacity(1);
    }
  };

  useEffect(() => {
    const handleScrollStop = () => {
      setIsScrolling(false);
      setOpacity(1);
    };

    let scrollTimeout;

    const handleScrollEvent = () => {
      handleScroll();
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(handleScrollStop, 150);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const getFabStyles = (currentTheme) => ({
    bgcolor: themeName === currentTheme ? "primary.main" : "secondary.yellow",
    color: themeName === currentTheme ? "inherit" : "primary.dark",
    "&:hover": {
      color: themeName === currentTheme ? "inherit" : "white",
      bgcolor: themeName === currentTheme ? "primary.dark" : "secondary.main",
    },
    mx: 1,
    zIndex: 1110001, // Ensure this is higher than the container's zIndex
  });

  return (
    // <Box
    //   component={motion.div}
    //   initial={false}
    //   animate={{
    //     opacity: opacity,
    //   }}
    //   transition={{ duration: 0.3, ease: "easeInOut" }}
    //   sx={{
    //     position: "fixed",
    //     right: "10px",
    //     top: scrolled ? "100px" : "100px",
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "center",
    //     zIndex: 1110000,
    //   }}
    //   gap={2}
    // >
    <>
      <Tooltip
        title="Operations Track"
        placement="left"
        arrow
      >
        <Fab
          size="small"
          onClick={() => handleThemeChange("TPM")}
          sx={getFabStyles("TPM")}
        >
          <TpmSvg width="2em" height="2em" />
        </Fab>
      </Tooltip>

      <Tooltip
        title="See Data Science Track"
        placement="left"
        arrow
      >
        <Fab
          size="small"
          onClick={() => handleThemeChange("DS")}
          sx={getFabStyles("DS")}
        >
          <DsSvg width="1.75em" height="1.75em" />
        </Fab>
      </Tooltip>

      <Tooltip
        title="See Stack Development Track"
        placement="left"
        arrow
      >
        <Fab
          size="small"
          onClick={() => handleThemeChange("FS")}
          sx={getFabStyles("FS")}
        >
          <FsSvg width="1.75em" height="1.75em" />
        </Fab>
      </Tooltip>
      </>
    // </Box>
  );
};

export default MyTracksButtons;
