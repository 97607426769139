import { Box, Typography, Button, IconButton, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import UnderConstruction from "../../ui/UnderConstruction";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useDialog } from "../../context/DialogContext";
import MyTracksButtons from "../MyTracksButtons";
import { motion } from 'framer-motion';

import { useThemeHandler } from "../../hooks/useThemeHandler";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  }, 
}));

export default function WeRinBeta() {
  const { open, showDialog } = useDialog();
  const [isOpen, setIsOpen] = useState(false);
  const { handleThemeChange } = useThemeHandler();

  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 15000); // Close dialog after 15 seconds
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const dialogVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 20,
      },
    },
    exit: {
      scale: 0,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 20,
      },
    },
  };

  return (
    // <React.Fragment>
    <motion.div
    variants={dialogVariants}
    initial="hidden"
    animate={isOpen ? 'visible' : 'hidden'}
    exit="exit"
  >
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Good News, Everyone!
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            {/* and then calls the UnderConstruction UI layout */}
            <UnderConstruction />
          </Box>
        </DialogContent>

        <DialogActions>
          <Container>
            <Box
              display="flex"
              style={{ justifyContent: "center", alignItems: "center" }}
              marginY={3}
            >
              <Typography variant="subTitle2" align="center">
                you can change the tracks by using these action buttons
              </Typography>
            </Box>
            <Box
              display="flex"
              style={{ justifyContent: "center", alignItems: "center" }}
              marginY={3}
            >
              <MyTracksButtons handleThemeChange={handleThemeChange} />
            </Box>
          </Container>
        </DialogActions>
      </BootstrapDialog>
      </motion.div>
    // </React.Fragment>
  );
}
