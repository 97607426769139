import React, { useEffect, useState, useContext } from "react";
import { Box, Fab, Tooltip } from "@mui/material";
import { ReactComponent as TpmSvg } from "../assets/PMIcon2.svg";
import { ReactComponent as DsSvg } from "../assets/DSicon.svg";
import { ReactComponent as FsSvg } from "../assets/FSicon.svg";
import { motion } from "framer-motion";
import { ThemeContext } from "../context/ThemeContext";
import MyTracksButtons from "./MyTracksButtons";

export default function MyTracks({handleThemeChange}){
    const { themeName } = useContext(ThemeContext);
  const [scrolled, setScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
      setIsScrolling(true);
      setOpacity(0.5);
    } else {
      setScrolled(false);
      setIsScrolling(false);
      setOpacity(1);
    }
  };

  useEffect(() => {
    const handleScrollStop = () => {
      setIsScrolling(false);
      setOpacity(1);
    };

    let scrollTimeout;

    const handleScrollEvent = () => {
      handleScroll();
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(handleScrollStop, 150);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);
    return(
        <Box
      component={motion.div}
      initial={false}
      animate={{
        opacity: opacity,
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      sx={{
        position: "fixed",
        right: "10px",
        top: scrolled ? "100px" : "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1110000,
      }}
      gap={2}
    >
        <MyTracksButtons handleThemeChange={handleThemeChange}/>
    </Box>
    )

}