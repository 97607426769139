import React from 'react';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ProfessionFeeder from "../EduWork/ProfessionFeeder";

export default function ProfessionalSection() {
  return (
    <Box
      sx={{
        position: "relative",
        height: "inherit",
        overflow: "auto",
      }}
    > 
      <Divider
        orientation="vertical"
        sx={{
          position: "absolute",
          left: { xs: "1%", sm: "50%" },
          top: 0,
          bottom: 0,
          borderRightWidth: 5,
          borderColor:"secondary.main",
          transform: "translateX(-50%)",
        }}
      />
      <Grid
        container
        spacing={{ xs: 2, sm: 3 }}
        padding={{ xs: 1, sm: 2 }}
      >
        <ProfessionFeeder />
      </Grid>
    </Box>
  );
};


