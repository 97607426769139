import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import ThemeContextProvider from './context/ThemeContext';
import DataContextProvider from './context/DataContext';

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeContextProvider>
        <DataContextProvider>
          <App />
        </DataContextProvider>
      </ThemeContextProvider>
    </CssBaseline>
  </React.StrictMode>
);
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
