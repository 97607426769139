import {
  Box,
  Container,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
// import ProfessionalFocus from "../components/ProfessionalFocus";
import ProfessionalSection from "../components/Section/ProfessionalSection";
import EducationalSection from "../components/Section/EducationalSection";
import { DataContext } from "../context/DataContext";

export default function ToggleEduWork() {
  const [section, setSection] = useState("professional");
  const { dataSet } = useContext(DataContext);

  const handleSectionChange = (event, newSection) => {
    if (newSection !== null) {
      setSection(newSection);
    }
  };
  return (
    <>
      {/* <Box height={`2rem`}></Box> */}
      {/* toggle work/education button group */}
      <Stack
        paddingY={`2`}
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        marginBottom={2}
        sx={{
          position: "sticky",
          top: 60,
          zIndex: 1000, // Ensure it stays above other content
          backgroundColor: "white", // Optional: to cover any content behind it
          // opacity:"0.7"
          // paddingTop: 2, // Optional: adds some space at the top
        }}
      >
        <Typography>Select to see Education or Work experience</Typography>
        <ToggleButtonGroup
          value={section}
          exclusive
          onChange={handleSectionChange}
          aria-label="section toggle"
          sx={{
            alignSelf: "center",
            marginBottom: 2,
          }}
        >
          <ToggleButton value="professional" aria-label="professional section">
            <WorkIcon sx={{ color: "secondary.main", marginRight: 2 }} />
            Work Experience
          </ToggleButton>
          <ToggleButton value="educational" aria-label="educational section">
            <SchoolIcon sx={{ color: "secondary.main", marginRight: 2 }} />
            Education
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {/* <Box height={`2rem`}></Box> */}
      <Stack direction="column">
        {/* Heading */}
        <Container sx={{ marginBottom: 2 }}>
          <Stack spacing={1} alignItems="center" marginBottom={2}>
            <Typography variant="h5">
              My{" "}
              {section === "professional"
                ? "experiences"
                : section === "educational"
                ? "education"
                : ""}{" "}
              in{" "}
            </Typography>
            <Typography variant="h4">{dataSet.SUBJECT_FOCUS[0]}</Typography>
          </Stack>
        </Container>
        {/* dynamic body */}
        {section === "professional" && <ProfessionalSection />}
        {section === "educational" && <EducationalSection />}
      </Stack>
    </>
  );
}
