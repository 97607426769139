import React, { createContext, useState } from 'react';
import DS_RES_DATA from '../data/DS_RES_DATA';
import FS_RES_DATA from '../data/FS_RES_DATA';
import TPM_RES_DATA from '../data/TPM_RES_DATA';

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
    const [dataSet, setDataSet] = useState(TPM_RES_DATA);

    const changeDataSet = (newDataSet) => {
        if (newDataSet && typeof newDataSet === 'object') {
            setDataSet(newDataSet);
        } else {
            console.error("newDataSet must be an object with the correct structure");
        }
    };

    return (
        <DataContext.Provider value={{ dataSet, changeDataSet }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;