export const TESTEMONIAL_DATA = [
  {
    name: "Sean Hammond",
    position: "Hammond Renewable Energy Inc.",
    body: [
      "Sam has proven to be a 'Swiss army knife' of capabilities who has a fantastic eye for detail and excels at process creation. ",
      // "Sam takes a hands-on and meticulous approach to all work ensuring that a high quality result is achieved. ",
    ],
    image: "./SeanH.jpg",
  },
  {
    name: "Dan Calhoun",
    position: "Corporate Senior Manager",
    body: [
      "Sam is highly respected by his co-workers for his willingness to help anyone anytime he can. ",
      // "Sam has been instrumental in helping institute new project cost savings and procedures.",
      // "Mr. Azimi has real leadership potential and I would highly recomment him for any position related to his field.",
    ],
    image: "./DanC.jpg",
  },
  {
    name: "Michael Suavé",
    position: "Michael C Suavé Consulting Inc.",
    body: [
      "Sam is a very organized individual that works well in a project team environment. ",
      // "Sam is detail oriented and is excellent at identifying priorities.  ",
      // "Sam promotes team spirit and always starts his coordination meetings, on time, every time. A real pleasure to work with. ",
    ],
    image: "./MikeS.jpg",
  },
  {
    name: "Sam Khattab",
    position: "Quality Management @ Clean AER",
    body: ["Knowledgeable, professional and a pleasure to work with.",],
    image: "./SamK.jpg",
  },
  {
    name: "Benoit Sorel",
    position: "Investor, Developer, and Mentor",
    body: ["Sam is a dedicated worker, who is smart, curious and fun to work with."],
    image: "./BenoitS.jpg",
  },
];
