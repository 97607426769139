import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import mainImage from "../../assets/Background.png";

import NavigationIcon from "@mui/icons-material/Navigation";
import { ImageList, ImageListItem } from "@mui/material";
import Fab from "@mui/material/Fab";
import TestemonialSection from "../../components/Section/TestemonialSection";
import AboutThisWebApp from "../../components/Section/AboutThisWebApp";

import { useDialog } from "../../context/DialogContext";
import { DataContext } from "../../context/DataContext";

export function HomePage() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const { dataSet } = useContext(DataContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to toggle the expanded state
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // under construction dialogue
  const { showDialog } = useDialog();

  useEffect(() => {
    showDialog();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginBottom: "2.5rem",
          // bgcolor: "primary.main",
          // background: 'linear-gradient(180deg, #7848AB, red)',
          // background: `linear-gradient(180deg, ${theme.gradient.start}, ${theme.gradient.end})`,
        }}
      >
        {/* My section 1 - Slogan Heading */}
        <Box
          width={"100vw"}
          sx={{
            background: `linear-gradient(180deg, ${theme.gradient.start}, ${theme.gradient.end})`,
          }}
        >
          <Container
          // sx={{
          //   background: `linear-gradient(180deg, ${theme.gradient.start}, ${theme.gradient.end})`,
          // }}
          >
            {/* HomePage banner */}
            <Grid
              container
              spacing={{ xs: 2, sm: 3 }}
              padding={{ xs: 1, sm: 2 }}
            >
              {/* HomePage Title */}
              <Grid item xs={12} sm={6} md={5} sx={{ order: isMobile ? 2 : 1 }}>
                <Container
                  sx={{
                    color: "white", // text color
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    rowGap: { sm: "2rem", md: "3rem" },
                  }}
                >
                  {/* Title slogan */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      // color="black"
                    >
                      Hi I am Sam, and I bring you ...
                    </Typography>
                    <Typography variant="sloganH4">
                      DATA AT YOUR FINGERTIPS
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      // color="black"
                    >
                      ... that will help your business prosper!
                    </Typography>
                  </Box>

                  {/* Title paragraph */}
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      color="white"
                      // transition="all 13s ease-in-out"
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        WebkitLineClamp: expanded ? "none" : 4, // 'none' when expanded, 4 lines otherwise
                        lineClamp: expanded ? "none" : 4, // fallback for non-webkit browsers
                      }}
                    >
                      I am an <strong>Engineer</strong> and{" "}
                      <strong>{dataSet.PROFESSION[0]}</strong> who is passionate
                      about using AI to analyze data to meet your goals. I love
                      math so I can nerd it out when needed. I make data
                      analytics fun by visualizing it and narrating it as a
                      story. I am a firm believer of using colourful graphs and
                      charts to present data storytelling flow of your KPI's. My
                      solid training and extensive experience will help with
                      predicting the business results of your potential next
                      steps.
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <IconButton
                        aria-label="Show More"
                        sx={{ color: "white" }}
                        onClick={handleToggle}
                      >
                        {expanded ? (
                          <>
                            <Typography variant="subtitle2">
                              see less
                            </Typography>
                            <ExpandLessIcon />
                          </>
                        ) : (
                          <>
                            <Typography variant="subtitle2">
                              see more
                            </Typography>
                            <ExpandMoreIcon />
                          </>
                        )}
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Title Button */}
                  <Stack width={1} marginY={2} sx={{ "& button": { mx: 3 } }}>
                    <Fab
                      variant="extended"
                      onClick={() => {
                        navigate("/resume");
                      }}
                      sx={{
                        bgcolor: "secondary.yellow",
                        fontSize: !isMobile ? "1.3rem" : ".9rem",
                        color: "primary.dark",
                        "&:hover": {
                          color: "white",
                          bgcolor: "secondary.main",
                        },
                      }}
                    >
                      See my Resume
                      <NavigationIcon sx={{ ml: 1 }} />
                    </Fab>
                    <Box marginY={2}></Box>
                  </Stack>
                </Container>
              </Grid>

              {/* HomePage Main Image */}
              <Grid item xs={12} sm={6} md={7} sx={{ order: isMobile ? 1 : 2 }}>
                <ImageList cols={1}>
                  <ImageListItem>
                    <img
                      src={mainImage}
                      alt="Graphic doodle of a guy sitting on a beanbag with his laptop working on great ideas"
                      style={{
                        border: "solid primary.main",
                        borderRadius: "2rem",
                      }}
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* My section 2 - Customer Service and one testemonial*/}
        <Box
          width={"100vw"}
          boxShadow={4}
          sx={{
            bgcolor: "white",
            paddingTop: "2rem", //desktop only, must adjust for mobile and tablet
          }}
        >
          {/* this is the testimonials section */}
          <TestemonialSection />
        </Box>

        {/* My section 3 - a little about this web app*/}
        <Box
          width={"100vw"}
          boxShadow={4}
          sx={{
            // bgcolor: `primary.light`,
            background: `linear-gradient(180deg, ${theme.gradient.mid}, ${theme.gradient.end})`,
            paddingTop: "2rem", //desktop only, must adjust for mobile and tablet
          }}
        >
          {/* this is the testimonials section */}
          <AboutThisWebApp />
          {/* a little about this web app! */}
        </Box>
      </Box>
    </>
  );
}
