// src/hooks/useThemeHandler.js
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { DataContext } from '../context/DataContext';
import DS_RES_DATA from '../data/DS_RES_DATA';
import FS_RES_DATA from '../data/FS_RES_DATA';
import TPM_RES_DATA from '../data/TPM_RES_DATA';

export const useThemeHandler = () => {
  const { themeName, changeTheme } = useContext(ThemeContext);
  const { dataSet, changeDataSet } = useContext(DataContext);

  const handleThemeChange = (newTheme) => {
    changeTheme(newTheme);
    switch (newTheme) {
      case "TPM":
        changeDataSet(TPM_RES_DATA);
        break;
      case "DS":
        changeDataSet(DS_RES_DATA);
        break;
      case "FS":
        changeDataSet(FS_RES_DATA);
        break;
      default:
        break;
    }
  };

  return {
    themeName,
    dataSet,
    handleThemeChange,
  };
};
