import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Facebook, Twitter, Instagram, ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const socialMediaData = [
  {
    name: "LinkedIn",
    icon: <LinkedInIcon />,
    value: "linkedin.com/in/samazimidotcom",
    link: "https://www.linkedin.com/in/samazimidotcom",
  },
  {
    name: "GitHub",
    icon: <GitHubIcon />,
    value: "github.com/dinozio-design",
    link: "https://github.com/dinozio-design",
  },
  {
    name: "Email",
    icon: <EmailIcon />,
    value: "sam.azimi.dev@gmail.com",
    link: "mailto:sam.azimi.dev@gmail.com",
  },
  {
    name: "Phone",
    icon: <PhoneIcon />,
    value: "+1.647.678.9571",
    link: "tel:+16476789571",
  },
];

export default function SocialMediaContainer() {
  const [copied, setCopied] = useState("");

  const handleCopy = (value) => {
    setCopied(value);
    alert(`${value} copied to clipboard!`);
  };

  const handleIconClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: 2,
          gap: 2,
        }}
      >
        {socialMediaData.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" sx={{marginBottom:{xs:3, sm:0}}}
          // marginBottom={3}
          >
            <Tooltip 
            // key={item.name} 
            title={item.name}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => handleIconClick(item.link)}
              >
                {item.icon}
              </Fab>
            </Tooltip>
            {/* <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={item.icon}
          >
            {item.name}
          </Button> */}
            {/* <IconButton  onClick={() => handleIconClick(item.link)}>
              {item.icon}
            </IconButton> */}

            {/* <CopyToClipboard
              text={item.value}
              onCopy={() => handleCopy(item.value)}
            >
              <IconButton>
                <ContentCopy />
              </IconButton>
            </CopyToClipboard> */}
          </Box>
          // </Tooltip>
        ))}
        {/* {copied && (
        <Alert
          severity="success"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          {copied} copied to clipboard!
        </Alert>
      )} */}
      </Box>
    </>
  );
}

// export default SocialMediaContainer;
