import React from 'react';
import TestCardForm from './TestCardForm';
import TestCardFetch from './TestCardFetch';
import CardDetails from './CardDetails';

export default function FunWithCodePage() {
  return (
    <>
    {/* <div>
      <h1>Ackroo Test Cards</h1>
      <TestCardForm />
    </div>
    <div>
    <h1>Ackroo Test Cards</h1>
    <TestCardFetch />
  </div> */}
    <div>
    <h1>Ackroo Card Detail</h1>
    <CardDetails />
  </div>
    </>
  );
}