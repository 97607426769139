import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Link } from "@mui/material";
import SocialMediaContainer from "./SocialMediaContainer";
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Home, Description } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';


const drawerWidth = 240;
const navItems = [
  {
    name: "Home",
    icon: <Home/>, //<Facebook />,
    value: "HOME",
    link: "/",
  },
  {
    name: "Resume",
    icon: <Description/>, // <Twitter />,
    value: "Resume",
    link: "/resume",
  },
];

// This will hide the navbar as user scroles
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function DrawerAppBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleNavlinkClick = (targetPath) => {
    // window.location.pathname == targetPath;
    navigate(targetPath);
    handleMenuClose();
  };

  
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Grid
      display={`flex`}
      flexDirection={`column`}
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "100vh" }}
    >
      <span onClick={() => handleNavlinkClick("/")}>
        <Typography variant="h4" sx={{ my: 2, paddingTop: 2 }}>
          Sam Azimi
        </Typography>
      </span>
      <Divider />

      <Stack
        flexGrow={1}
        spacing={3}
        height={`auto`}
        paddingBottom={3}
        marginBottom={`4rem`}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          justifySelf: "flex-end",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          paddingBottom={3}
          sx={{ display: { xs: "flex", sm: "none" } }}
        >
          <Button
            sx={{ color: "inherit", fontSize: "2rem" }}
            onClick={() => handleNavlinkClick("/")}
          >
            Home
          </Button>
          <Button
            sx={{ color: "inherit", fontSize: "2rem" }}
            onClick={() => handleNavlinkClick("/resume")}
          >
            Resume
          </Button>
        </Stack>
        {/* <Box flexGrow={1} /> */}
        {/* Add social icon group here */}
        {/* <Stack mb={`2rem`}> */}
        <SocialMediaContainer />
        {/* </Stack> */}
      </Stack>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", bgcolor:"primary.dark" }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          component="nav"
          sx={{ bgcolor: "primary.dark", color: "white" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h4"
              component="div"
              // sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              sx={{
                flexGrow: 1,
                // display: { sm: "block" },
                display: { xs: mobileOpen ? "none" : "block", sm: "block" },
                textAlign: { xs: "center", sm: "inherit" },
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                Sam Azimi
              </Link>
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              px={2}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              {navItems.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Tooltip 
                  // key={item.name} 
                  title={item.name}>
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => handleNavlinkClick(item.link)}
                    >
                      {item.icon}
                    </Fab>
                  </Tooltip>
                </Box>
              ))}

              {/* old navlinks */}
              {/* <Button
                sx={{ color: "inherit" }}
                onClick={() => handleNavlinkClick("/")}
              >
                Home
              </Button>
              <Button
                sx={{ color: "inherit" }}
                onClick={() => handleNavlinkClick("/resume")}
              >
                Resume
              </Button> */}

              {/* <Button sx={{ color: "inherit" }}>Projects</Button> */}

              {/* <Button sx={{ color: "inherit" }}>Articles</Button> */}

              {/* <Button
                id="about-button"
                onClick={handleMenuClick}
                sx={{ color: "inherit" }}
                aria-controls={open ? "about-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                endIcon={<KeyboardArrowDownIcon />}
              >
                About
              </Button> */}
            </Stack>
            <Stack
              direction={`row`}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <SocialMediaContainer />
            </Stack>
            <Menu
              id="about-menu"
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "about-button",
              }}
              onClose={handleMenuClose}
            >
              <MenuItem key="resume" onClick={() => handleNavlinkClick("/resume")}>
                Resume
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "primary.dark",
              color: "white",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
