// this component's sole purpose is to render each card whenever called

/** it takes the following props
 *
 *
 */
import { Grid, Rating } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function TCard({ name, position, body, image }) {
  return (
    <Card>
      <CardContent>
        {/* this will hold image on the left and name/stars on the right */}
        <Grid item container xs={12}>
          <Grid item 
          display={`flex`} 
          alignItems={`center`}
          justifyContent={`center`}
          xs={3} lg={2}>
            <img
              src={image}
              alt={`Photo of ${name}`}
              style={{
                borderRadius: "50%",
                width: "80%",
                maxWidth: "60px",
                aspectRatio: "1 / 1",
              }}
            />
          </Grid>
          {/* this is name and stars */}
          <Grid item xs={9} paddingLeft={2}>
            {/* name */}
            {/* <Stack> */}
            <Typography variant="subtitle1" paddingLeft={1}>
              {name}
            </Typography>
            {/* </Stack> */}
            {/* star */}
            {/* <Stack paddingLeft={1}> */}
            <Rating paddingLeft={1} name="read-only" value={5} readOnly />
            {/* </Stack> */}
          </Grid>
        </Grid>
                {/* This is position */}
      <Grid item xs={12} paddingX={2} paddingTop={1} >
        <Typography variant="subtitle2" paddingLeft={1} fontSize=".75rem">
          {position}
        </Typography>
      </Grid>
        {/* This is paragraph */}
      <Grid item xs={12} paddingX={2} paddingTop={1}>
      {body.map((paragraph, index) => (
        <Typography 
        align="justify" 
        key={index} variant="body2" paragraph>
          {paragraph}
        </Typography>
      ))}
      </Grid>
      </CardContent>
    </Card>
  );
}
