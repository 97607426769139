
import { Box, Modal, Typography, } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeHandler } from "../../hooks/useThemeHandler";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

export default function CircleCard({title, 
  description,
  isOpen, 
  handleClose,
  context,
  imageSrc,
}){
  const theme = useTheme();
  const { handleThemeChange } = useThemeHandler();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

const handleVisitClick = () => {
  handleThemeChange(context);
  navigate('/resume');
};

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-title">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            // backgroundImage: `url(${imageSrc})`,
            // backgroundSize: "cover",
            border: !isMobile ? "2px solid white" : "none" ,
            borderRadius: "15%",
            boxShadow: 24,
            p: 4,
            justifyContent: "center",
            alignItems: "center",
            width: !isMobile ? "50vh" : "90vw",
            height: !isMobile ? "50vh" : "90vw",
          }}
        >
          <Box width={`50%`} p={1}>
            <img
              src={imageSrc}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <Typography variant="body2" 
            // align="justify"
            >
              {description}
            </Typography>
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleVisitClick}>
              Visit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}