import { Box, Card, Container, Dialog, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import Grow from '@mui/material/Grow';
import React, { useContext , useState} from "react";
// import DS_RES_DATA from '../../data/DS_RES_DATA';
// import FS_RES_DATA from '../../data/FS_RES_DATA';
// import TPM_RES_DATA from '../../data/TPM_RES_DATA';
import CircleCard from "../CircleCard/CircleCard";
import cardImageTPM from "../../assets/TPM_Card.png";
import cardImageDS from "../../assets/DS_Card.png";
import cardImageFS from "../../assets/FS_Card.png";

const tracks = [
  
  {
    title: "Analytics",
    // dataSet: DS_RES_DATA,
    context: "DS",
    description:
      "Analyzing complex datasets, solving complex analytical problems, and driving data-driven decision-making initiatives in this fast-paced industry.",
    imageSrc: cardImageDS,
  },
  {
    title: "Operations",
    // dataSet: TPM_RES_DATA,
    context: "TPM",
    description:
      "Leading cross-functional teams, solving complex technical problems, and driving continuous improvement initiatives in this fast-paced industry.",
    imageSrc: cardImageTPM,
  },
  {
    title: "Development",
    // dataSet: FS_RES_DATA,
    context: "FS",
    description:
      "Passionate about crafting seamless user experiences, I excel in writing JavaScript code to develop robust and intuitive front-end web applications.",
    imageSrc: cardImageFS,
  },
];


export default function AboutThisWebApp(){
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const circleSize = "10rem"; // Adjust size as needed
  const [openIndex, setOpenIndex] = useState(null); // State to track which card is open


const handleCircleCardClick = (index) => {
    setOpenIndex(index === openIndex ? null : index); // Toggle card open/close
  }

  return (
    <>
      <Container>
        <Typography align="center" variant="h4">
          I have indexed my diverse experience into three groups or "Tracks":
        </Typography>
      </Container>

      <Grid container alignItems={`center`} marginY={2}>
        {tracks.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            display={`flex`}
            justifyContent={`center`}
          >
            <Stack
            justifyContent="center"
            alignItems="center"
            marginBottom={4}
            >
            <Grow in="true">
            {/* Circle Box */}
            <Box
              sx={{
                // border: "solid thick",
                p: 2,
                m: 1,
                // borderRadius: "50%",
                width: circleSize,
                height: circleSize,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundImage: `url(${item.imageSrc})`,
            backgroundSize: "cover",
              }}
              onClick={()=> handleCircleCardClick(index)}
            />
            
            </Grow>
              {item.title}
              </Stack>

{openIndex === index && (
              <CircleCard
                title={item.title}
                description={item.description}
                context={item.context}
                imageSrc={item.imageSrc}
                isOpen={openIndex === index}
                handleClose={() => setOpenIndex(null)}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Container>
        <Typography align="center" variant="h6">
          You can choose a track and this web app will dynamically presents the
          associated track of education and experience for your ease of
          browsing.
        </Typography>
      </Container>
    </>
  );
}