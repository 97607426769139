import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";
// import Hex from "../Hexagons/Hex";
export default function CoreCompetencies() {
  const { dataSet } = useContext(DataContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (!Array.isArray(dataSet.CORE_COMPETENCIES)) {
    return <Typography>Error: CORE_COMPETENCIES is not an array</Typography>;
  }
// const hexHeight = "10rem";
const circleSize = "8rem"; // Adjust size as needed
const itemsToShow = isMobile ? 4 : 7;
  return (
    <>
      <Grid container alignItems={`center`} marginY={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" marginRight={2}>
            {/* My Core Competencies as a{" "} */}
            {dataSet.SUBJECT_FOCUS[0]} Core Competencies
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {dataSet.CORE_COMPETENCIES.slice(0, itemsToShow).map((item, index) => (
          <>
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* <Hex
                key={index}
                content={item}
                shiftDown={index % 2 === 0}
                height={hexHeight}
              /> */}

              {/* Circle Box */}
              <Box 
              key={index} 
              sx={{ 
                border: 'solid thick',
                p: 2,
                m:1,
                borderRadius: '50%',
                width: circleSize,
                height: circleSize,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                }}>
                {item}
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </>
  );
}
//
